import React, { useState, useContext } from "react"
import { graphql } from "gatsby"
import {
  makeStyles,
  Grid,
  TextField,
  LinearProgress,
  Container,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableRow,
  TableCell,
  List,
  ListItem,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  Button,
} from "@material-ui/core"
import {
  ExpandMore,
  AddCircle,
  RemoveCircle,
  Undo,
  Redo,
  DoneAll,
} from "@material-ui/icons"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BasketContext from "../contexts/basket"

const services = [
  {
    title: "Servis",
    items: [
      { title: "Mali servis", effort: 14, eur: 35.0 },
      { title: "Veliki servis", effort: 19, eur: 55.0 },
      { title: "Generalni servis", effort: 30, eur: 100.0 },
      { title: "Nulti servis (sastavljanje bicikla)", effort: 14, eur: 45.0 },
      {
        title: "Nadoplata za hitan servis (sljedeći dan)",
        effort: -100,
        eur: 20.0,
      },
      {
        title: "Nadoplata za hitan servis (isti dan)",
        effort: -100,
        eur: 35.0,
      },
    ],
  },
  {
    title: "Kočnice",
    items: [
      { title: "Podešavanje V-Brake kočnice", effort: 4, eur: 6.0 },
      { title: "Zamjena pakni s podešavanjem", effort: 5, eur: 7.0 },
      { title: "Zamjena čeljusti kočnice", effort: 6, eur: 10.0 },
      { title: "Ugradnja ručice kočnice", effort: 5, eur: 9.0 },
      { title: "Zamjena diska kočnice", effort: 5, eur: 7.0 },
      { title: "Podešavanje disk kočnice", effort: 4, eur: 6.0 },
      { title: "Zamjena sajle kočnice s podešavanjem", effort: 5, eur: 10.0 },
      { title: "Ugradnja mehaničke disk kočnice", effort: 8, eur: 12.0 },
      { title: "Ugradnja hidraulične disk kočnice", effort: 7, eur: 12.0 },
      {
        title: "Ugradnja hidraulične disk kočnice s odzračivanjem",
        effort: 9,
        eur: 20.0,
      },
      { title: "Odzračivanje kočnice (mineralno ulje)", effort: 8, eur: 14.0 },
      { title: "Odzračivanje kočnice (DOT ulje)", effort: 10, eur: 16.0 },
    ],
  },
  {
    title: "Kotači",
    items: [
      { title: "Centriranje kotača male zakrivljenosti", effort: 6, eur: 10.0 },
      {
        title: "Centriranje kotača velike zakrivljenosti",
        effort: 7,
        eur: 12.0,
      },
      { title: "Zamjena žbice kotača s centriranjem", effort: 8, eur: 15.0 },
      { title: "Preslagivanje kotača", effort: 14, eur: 30.0 },
      { title: "Servis prednje glavčine", effort: 8, eur: 8.0 },
      { title: "Servis stražnje glavčine", effort: 9, eur: 14.0 },
      { title: "Ugradnja prednjeg kotača", effort: 8, eur: 7.0 },
      { title: "Ugradnja stražnjeg kotača", effort: 9, eur: 11.0 },
      { title: "Zamjena stražnjeg lančanika", effort: 3, eur: 4.0 },
      {
        title: "Zamjena gume/zračnice (standardni bicikli)",
        effort: 3,
        eur: 5.0,
      },
      {
        title:
          "Zamjena gume/zračnice na biciklima s elektromotorom u kotaču i sl.",
        effort: 9,
        eur: 12.0,
      },
      {
        title: "Tubeless postavljanje bez montiranja trake",
        effort: 7,
        eur: 8.0,
      },
      {
        title: "Tubeless postavljanje sa montiranjem trake",
        effort: 9,
        eur: 15.0,
      },
      {
        title: "Dodavanje tubeless tekućine (bez materijala)",
        effort: 5,
        eur: 3.0,
      },
    ],
  },
  {
    title: "Pogonski dio",
    items: [
      { title: "Ugradnja pogonskog ležaja", effort: 11, eur: 15.0 },
      { title: "Ugradnja pogona", effort: 8, eur: 12.0 },
      { title: "Ugradnja mjenjača s podešavanjem", effort: 8, eur: 11.0 },
      { title: "Ugradnja lanca", effort: 3, eur: 6.0 },
      {
        title: "Ugradnja ručice mjenjača s podešavanjem",
        effort: 8,
        eur: 12.0,
      },
      { title: "Zamjena sajle mjenjača s podešavanjem", effort: 7, eur: 11.0 },
      { title: "Podešavanje brzina", effort: 6, eur: 8.0 },
      { title: "Spajanje lanca", effort: 3, eur: 4.0 },
      { title: "Ugradnja pedala", effort: 4, eur: 4.0 },
    ],
  },
  {
    title: "Upravljački dio",
    items: [
      { title: "Zamjena ležaja vilice", effort: 17, eur: 15.0 },
      { title: "Ugradnja vilice sa servisom ležaja", effort: 17, eur: 17.0 },
      { title: "Ugradnja rogova", effort: 4, eur: 3.0 },
      { title: "Ugradnja gripova", effort: 2, eur: 3.0 },
      { title: "Ugradnja upravljača", effort: 11, eur: 10.0 },
      { title: "Ugradnja lule upravljača", effort: 8, eur: 6.0 },
      { title: "Motanje trake na cestovnim biciklima", effort: 11, eur: 12.0 },
      { title: "Skraćivanje upravljača", effort: 10, eur: 10.0 },
    ],
  },
  {
    title: "Ostalo",
    items: [
      // { title: "Radni sat servisa", effort: 5, eur: 30.00},
      // { title: "Potrošni materijal", effort: 5, eur: 1.00},
      // { title: "Skladištenje bicikla po danu", effort: 0, eur: 2.00},
      // { title: "Zamjena okvira bicikla", koncept
      { title: "Ugradnja ciklokompjutera", effort: 5, eur: 8.0 },
      { title: "Ugradnja nogara", effort: 3, eur: 3.0 },
      { title: "Ugradnja blatobrana", effort: 9, eur: 10.0 },
      { title: "Ugradnja nosača tereta", effort: 8, eur: 8.0 },
      { title: "Ugradnja dječje sjedalice", effort: 9, eur: 12.0 },
      { title: "Ugradnja sjedala", effort: 5, eur: 4.0 },
      { title: "Ugradnja pomoćnih kotača", effort: 7, eur: 7.0 },
      { title: "Ugradnja košare", effort: 6, eur: 7.0 },
      { title: "Ugradnja mehaničkog droppera", effort: 12, eur: 15.0 },
      { title: "Ugradnja hidrauličnog droppera", effort: 23, eur: 25.0 },
    ],
  },
]

const formToObject = elem => {
  let output = {}
  if (!elem) return output

  let form = new FormData(elem)
  let entries = Array.from(form.entries())
  entries.forEach(pair => {
    let key = pair[0]
    let value = pair[1]

    if (Object.prototype.hasOwnProperty.call(output, key)) {
      let current = output[key]
      if (!Array.isArray(current)) current = output[key] = [current]

      current.push(value)
    } else {
      output[key] = value
    }
  })

  return output
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    maxWidth: "100vw",
    width: "100vw",
    paddingLeft: "5px",
    paddingRight: "5px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "24px",
      paddingRight: "24px",
    },
  },
  mainHeading: {
    padding: "20px",
    fontSize: "2.5vh !important",
  },
  mainDescription: {
    fontSize: "2vh",
  },
  secondaryDescription: {
    fontSize: "2vh",
  },
  root: {
    width: "100%",
    fontSize: "1.8vh",
  },
  stepper: {
    padding: "5px",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  twoVH: { fontSize: "2vh" },
  onePointEightVH: { fontSize: "1.8vh" },
  accordion: {
    marginBottom: "5px",
  },
  heading: {
    color: "#fff",
    backgroundColor: "#212121",
    marginTop: "2px",
    borderRadius: "2px",
  },
  accordionDetails: {
    overflow: "scroll",
    [theme.breakpoints.up("md")]: {
      overflow: "hidden",
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    width: "70%",
    fontSize: "1.8vh",
    fontWeight: 900,
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    textAlign: "center",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  chip: {
    marginLeft: "auto",
  },
  removeCircleIcon: {
    color: "#ff0000",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#d10000",
    },
  },
  addCircleIcon: {
    color: "#22ff00",
    transition: "all .2s ease-in-out",
    "&:hover": {
      transform: "scale(1.2)",
      color: "#1bc700",
    },
  },
  listItem: {
    border: "1px solid black",
    borderRadius: "5px",
    marginBottom: "5px",
    justifyContent: "center",
    "&:focus": { backgroundColor: "#414141", color: "#fff" },
    "&:hover": { backgroundColor: "#000 !important", color: "#fff" },
    "&.Mui-selected": {
      backgroundColor: "#000",
      color: "#fff",
      "&::after": { content: `"↩"`, fontSize: "1.8vh", paddingLeft: "2%" },
      "&::before": { content: `"↪"`, fontSize: "1.8vh", paddingRight: "2%" },
    },
  },
  nextIcon: {
    marginLeft: "8px",
  },
  backIcon: {
    marginRight: "8px",
  },
  sendIcon: {
    marginLeft: "8px",
  },
}))

const NaruciSe = props => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [selectedServices, setSelectedServices] = useState({})
  const [totalEffort, setTotalEffort] = useState(0)
  const [loadingDates, setLoadingDates] = useState(true)
  const [dates, setDates] = useState([])
  const [selectedDate, setSelectedDate] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const incrementService = (title, effort) => {
    if (title in selectedServices) selectedServices[title]++
    else selectedServices[title] = 1

    setTotalEffort(prevEffort => prevEffort + effort)
    setSelectedServices({ ...selectedServices })
  }

  const decrementService = (title, effort) => {
    if (title in selectedServices)
      if (selectedServices[title] === 1) delete selectedServices[title]
      else selectedServices[title]--

    setTotalEffort(prevEffort => prevEffort - effort)
    setSelectedServices({ ...selectedServices })
  }

  const handleServicesNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    const request = {
      body: JSON.stringify({ effort: totalEffort }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }
    //fetch('https://localhost:5001/api' + '/dates', request)
    fetch(props.data.site.siteMetadata.orderApi + "/dates", request).then(r =>
      r.json().then(o => {
        setDates(o)
        setLoadingDates(false)
      })
    )
  }

  const handleDatesNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleDatesBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    setDates([])
    setSelectedDate("")
    setLoadingDates(true)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleSubmit = event => {
    setSubmitting(true)
    event.preventDefault()

    const contact = formToObject(event.target)
    basket.updateContact({
      ...basket.contact,
      firstName: contact.firstName,
      lastName: contact.lastName,
      email: contact.email,
      tel: contact.tel,
    })
    const request = {
      body: JSON.stringify({
        ...contact,
        date: selectedDate,
        services: { ...selectedServices },
      }),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }

    // const mail = fetch('http://localhost:5000/ciklo-web-service', request);
    // const service = fetch('https://localhost:5001/api' + '/request', request);
    const mail = fetch(props.data.site.siteMetadata.serviceApi, request)
    const service = fetch(
      props.data.site.siteMetadata.orderApi + "/request",
      request
    )

    Promise.all([mail, service]).then(values => {
      setSubmitting(false)
      setSubmitted(true)
      console.log(values)
    })
  }

  const basket = useContext(BasketContext)

  if (submitted)
    return (
      <Layout location={props.location} title={"Naruči se na servis"}>
        <SEO title="Naruči se na servis" />
        <Grid
          md={12}
          style={{ height: "12vh", background: "#000", width: "100%" }}
        />
        <Typography
          variant="h4"
          component="h1"
          align="center"
          color="primary"
          gutterBottom
          className={classes.twoVH}
        >
          Narudžba poslana. Djelatnici će vam se javiti s povratnom
          informacijom.
        </Typography>
      </Layout>
    )

  return (
    <Layout location={props.location} title={"Naruči se na servis"}>
      <SEO title="Naruči se na servis" />
      <Grid
        md={12}
        style={{ height: "12vh", background: "#000", width: "100%" }}
      />{" "}
      <Container className={classes.mainContainer}>
        <Typography
          className={classes.mainHeading}
          variant="h4"
          component="h1"
          align="center"
          color="primary"
          gutterBottom
        >
          Servis - online naručivanje
        </Typography>
        <Typography
          className={classes.mainDescription}
          variant="body1"
          component="h2"
          align="center"
          color="primary"
          gutterBottom
        >
          Trebaš servis, popravak ili ugradnju nekog dijela? Na pravom si
          mjestu.
        </Typography>
        <Typography
          className={classes.secondaryDescription}
          variant="body1"
          component="h3"
          align="center"
          color="primary"
          gutterBottom
        >
          Prvo odaberi tip usluge koju trebaš, zatim izaberi termin koji ti
          odgovara, ispuni formu sa svojim podacima i termin će te čekati!
        </Typography>
        <div className={classes.root}>
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            className={classes.stepper}
          >
            <Step>
              <StepLabel>
                <Typography
                  variant="body1"
                  component="h3"
                  className={classes.twoVH}
                >
                  Odabir usluga
                </Typography>
              </StepLabel>
              <StepContent>
                {services.map(service => (
                  <Accordion className={classes.accordion} key={service.title}>
                    <AccordionSummary
                      className={classes.heading}
                      expandIcon={<ExpandMore color="secondary" />}
                    >
                      <Typography className={classes.onePointEightVH}>
                        {service.title}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                      <Table className={classes.table} size="small">
                        <TableBody>
                          {service.items.map(row => (
                            <TableRow key={row.title}>
                              <TableCell
                                component="th"
                                scope="row"
                                className={classes.onePointEightVH}
                              >
                                {row.title}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.onePointEightVH}
                              >
                                {row.eur.toFixed(2)}&nbsp;€
                              </TableCell>
                              <TableCell align="right">
                                <Chip
                                  className={classes.chip}
                                  clickable
                                  variant="outlined"
                                  deleteIcon={
                                    <RemoveCircle
                                      className={classes.removeCircleIcon}
                                    />
                                  }
                                  label={
                                    row.title in selectedServices
                                      ? selectedServices[row.title]
                                      : 0
                                  }
                                  onClick={() =>
                                    incrementService(row.title, row.effort)
                                  }
                                  onDelete={() =>
                                    decrementService(row.title, row.effort)
                                  }
                                  icon={
                                    <AddCircle
                                      className={classes.addCircleIcon}
                                    />
                                  }
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <div className={classes.actionsContainer}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleServicesNext}
                    className={classes.button}
                    disabled={Object.keys(selectedServices).length === 0}
                  >
                    {" "}
                    Dalje <Redo className={classes.nextIcon} />
                  </Button>
                </div>
              </StepContent>
            </Step>
            <Step>
              <StepLabel>
                <Typography
                  variant="body1"
                  component="h3"
                  className={classes.twoVH}
                >
                  Odabir termina
                </Typography>
              </StepLabel>
              {loadingDates && (
                <StepContent>
                  <p className={classes.onePointEightVH}>
                    Dohvaćanje dostupnih datuma...
                  </p>
                  <LinearProgress />
                </StepContent>
              )}
              {!loadingDates && (
                <StepContent>
                  <p className={classes.onePointEightVH}>
                    Trudimo se svaki servis, popravak ili ugradnju obaviti u
                    jednom danu. Iz tog razloga, Ciklo-Sport servis funkcionira
                    po principu naručivanja. Na taj način ti nećeš biti dugo bez
                    bicikla, a nama se ne stvara gužva.
                  </p>
                  <p className={classes.onePointEightVH}>
                    Procedura ostavljanja bicikla je da bicikl dovezeš na
                    rezerviran datum, ujutro između 8 i 10 sati te će radovi na
                    tvom dvokotačnom ljubimcu biti završeni do 17 sati isti dan.
                    Ako ne možeš ostaviti bicikl ujutro, u tom slučaju je moguće
                    bicikl dovesti radni dan prije odabranog datuma termina,
                    bilo kad u radnom vremenu poslovnice.
                  </p>
                  <p className={classes.onePointEightVH}>
                    U nastavku te molimo da odabereš jedan od slobodnih termina
                    koji ti odgovara za ostavljanje bicikla:
                  </p>
                  <List className={classes.root}>
                    {dates.map(date => (
                      <ListItem
                        className={classes.listItem}
                        key={date.date}
                        button
                        selected={selectedDate === date.date}
                        onClick={() => setSelectedDate(date.date)}
                      >
                        {date.text}
                      </ListItem>
                    ))}
                  </List>
                  <div className={classes.actionsContainer}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleDatesBack}
                      className={classes.button}
                    >
                      <Undo className={classes.backIcon} /> Natrag{" "}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDatesNext}
                      className={classes.button}
                      disabled={!selectedDate}
                    >
                      {" "}
                      Dalje <Redo className={classes.nextIcon} />
                    </Button>
                  </div>
                </StepContent>
              )}
            </Step>
            <Step>
              <StepLabel>
                <Typography
                  variant="body1"
                  component="h3"
                  className={classes.twoVH}
                >
                  Tvoji podaci
                </Typography>
              </StepLabel>
              <StepContent>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        id="firstName"
                        name="firstName"
                        label="Ime"
                        fullWidth
                        autoComplete="given-name"
                        defaultValue={basket.contact.firstName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        name="lastName"
                        label="Prezime"
                        fullWidth
                        autoComplete="family-name"
                        defaultValue={basket.contact.lastName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        name="email"
                        type="email"
                        label="Email"
                        fullWidth
                        autoComplete="billing email"
                        defaultValue={basket.contact.email}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        name="tel"
                        label="Kontakt broj *"
                        fullWidth
                        autoComplete="billing tel"
                        defaultValue={basket.contact.tel}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="subject"
                        label="Predmet"
                        fullWidth
                        required
                        placeholder="Marka bicikla, npr. Kona"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="note"
                        label="Napomena"
                        placeholder="Opis kvara, detalji i slično ako postoji"
                        fullWidth
                        multiline
                        rows={2}
                      />
                    </Grid>
                  </Grid>
                  <div className={classes.actionsContainer}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleBack}
                      className={classes.button}
                    >
                      <Undo className={classes.backIcon} /> Natrag{" "}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={submitting}
                      className={classes.button}
                    >
                      {" "}
                      Pošalji <DoneAll className={classes.sendIcon} />
                    </Button>
                  </div>
                </form>
              </StepContent>
            </Step>
          </Stepper>
        </div>
      </Container>
    </Layout>
  )
}

export default NaruciSe

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        serviceApi
        orderApi
      }
    }
  }
`
